<script>
import { mapState } from "vuex";
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";

import OrganizationChart from 'primevue/organizationchart';

export default {
  locales: {
    pt: {
      "This user has no sponsored.": "Este usuário não possui nenhum indicado.",
      "The user you are trying to access does not exist or is not on your network.":
        "O usuário que está tentando acessar não existe ou não está em sua rede.",
    },
    es: {
      "This user has no sponsored.": "Este usuario no tiene nominado.",
      "The user you are trying to access does not exist or is not on your network.":
        "El usuario al que intenta acceder no existe o no está en su red.",
    },
  },
  components: {
    Layout,
    OrganizationChart,
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
  },
  data() {
    return {
      id: this.$route.params.id,
      tree: [],
      treeConfig: { nodeWidth: 200, nodeHeight: 200, levelHeight: 200 },

      loading: true,
      empty: false,
      message: "",
    };
  },
  methods: {
    getMatriz() {
      this.loading = true;

      api.get("network/matriz").then((response) => {
        if (response.data.status == "success") {
          this.tree = response.data.tree;
        }

        this.loading = false;
      });
    },
  },
  mounted() {
    this.getMatriz();
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex">
          <h4 class="mb-0 font-size-18">{{ t("Matriz") }}</h4>
        </div>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body" style="overflow: auto;">
            <vue-tree class="d-none tree" :dataset="tree" :config="treeConfig" :collapse-enabled="true">
              <template v-slot:node="{ node }">
                <div class="tree-node">
                  <div class="tree-avatar">
                    <img v-if="node.data.avatar" class="rounded-circle header-profile-user" :src="node.data.avatar" />
                    <img v-else class="rounded-circle header-profile-user" :src="'https://ui-avatars.com/api/?background=9F0318&color=fff&name=' + account.user.name" alt="" />
                  </div>
                  <div class="tree-user">{{ node.data.username }}</div>
                </div>
              </template>
            </vue-tree>

            <OrganizationChart :value="tree" :collapsible="true" selectionMode="single">
              <template #person="slotProps">
                <div class="node-content">
                  <div class="tree-avatar">
                    <img v-if="slotProps.node.data.avatar" class="rounded-circle header-profile-user" :src="slotProps.node.data.avatar" />
                    <img v-else class="rounded-circle header-profile-user" :src="'https://ui-avatars.com/api/?background=9F0318&color=fff&name=' + slotProps.node.data.name" alt="" />
                  </div>
                  <div class="tree-username font-size-12 mb-2">{{ slotProps.node.data.username }}</div>
                  <svg v-if="slotProps.node.data.star == 'yes'" class="star" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24"
                    style="fill: rgba(231, 215, 47, 1);">
                    <path
                      d="M21.947 9.179a1.001 1.001 0 0 0-.868-.676l-5.701-.453-2.467-5.461a.998.998 0 0 0-1.822-.001L8.622 8.05l-5.701.453a1 1 0 0 0-.619 1.713l4.213 4.107-1.49 6.452a1 1 0 0 0 1.53 1.057L12 18.202l5.445 3.63a1.001 1.001 0 0 0 1.517-1.106l-1.829-6.4 4.536-4.082c.297-.268.406-.686.278-1.065z">
                    </path>
                  </svg>
                </div>
              </template>
            </OrganizationChart>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style>
.tree {
  width: 100%;
  height: calc(100vh - 200px);
}

.tree-node {
  background: #eee;
  border-radius: 10px;
  padding: 10px;
  text-align: center;
}

.node-content {
  text-align: center;
  background: #eee;
  border-radius: 10px;
  padding: 10px;
  max-width: 140px;
  line-height: 14px;
}

.node-content img {
  border-radius: 50%;
}

.node-content .star {
  position: absolute;
  top: -14px;
  right: -9px;
}

.p-organizationchart .p-organizationchart-node-content {
  display: inline-block;
  background: none;
  position: relative;
  border: 0;
  padding: 0;
}

.p-organizationchart .p-organizationchart-node-content.p-organizationchart-selectable-node:not(.p-highlight):hover {
  background: none;
}

.p-organizationchart-node-content .p-node-toggler .p-node-toggler-icon {
  top: 0;
}

.p-node-toggler-icon {
  background: #fff !important;
  border-radius: 50px;
  padding: 5px !important;
}
</style>